.contacts {
  position: relative;
  overflow: hidden;
  padding-top: 203px;
  padding-bottom: 103px;

  @include tablet {
    padding-top: 98px;
  }

  @include big {
    padding-top: 10.572916666666666vw;
    padding-bottom: 5.364583333333333vw;
  }

  &__highlight-1 {
    position: absolute;
    top: 22%;
    left: 43.5%;
    opacity: 0.6;

    @include big {
      top: 10.4vw;
      left: 43.5vw;
    }

    @include mobile {
      top: 39%;
      left: 17.5%;
      opacity: 0.7;
    }

    svg {
      width: 493px;
      height: 639px;
      @include big {
        width: 25.6776vw;
        height: 33.282vw;
      }
    }
  }

  &__highlight-2 {
    position: absolute;
    top: 39.6%;
    left: 34.4%;
    opacity: 0.6;

    @include big {
      top: 18.725vw;
      left: 34.4vw;
    }


    @include mobile {
      top: 54.6%;
      left: -16.6%;
      opacity: 0.7;
    }

    svg {
      width: 609px;
      height: 494px;
      @include big {
        width: 31.719vw;
        height: 25.7299vw;
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: 94px;
    line-height: 98px;
    letter-spacing: -0.03em;
    margin-bottom: 54px;
    max-width: 722px;

    @include big {
      font-size: 4.895833333333333vw;
      line-height: 5.104166666666667vw;
      margin-bottom: 2.8125vw;
      max-width: 37.604166666666664vw;
    }

    @include tablet {
      font-weight: 600;
      font-size: 34px;
      line-height: 44px;
      margin-bottom: 33px;
    }
  }

  &__left {
    margin-top: 5px;
    font-style: normal;
    z-index: 1;
    @include big {
      margin-top: 0.26041666666666663vw;
    }
    @include tablet {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 60px;
    }
    @include tablet-small {
      margin-bottom: 23px;
    }
  }

  &__mail, &__address {
    display: flex;
    align-items: flex-start;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 32px;

    @include big {
      font-size: 1.25vw;
      line-height: 1.6666666666666667vw;
      margin-bottom: 1.6666666666666667vw;
    }

    @include tablet-small {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 30px;
    }

    svg {
      width: 32px;
      height: 32px;
      margin-right: 8px;

      @include big {
        width: 1.6666666666666667vw;
        height: 1.6666666666666667vw;
        margin-right: 0.4166666666666667vw;
      }
    }
  }

  &__address-text {
    p {
      display: block;
      margin-bottom: 12px;
      @include big {
        margin-bottom: 0.625vw;
      }
      @include tablet-small {
        margin-bottom: 8px;
      }
    }
  }

  &__container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1408px;
    margin: 0 auto;
    @include big {
      max-width: 73.33333333333333vw;
    }
    @include tablet {
      flex-direction: column;
      align-items: center;
    }
  }

  &__right {
    max-width: 448px;
    width: 100%;
    z-index: 1;

    @include big {
      max-width: 23.333333333333332vw;
    }
  }

  &__form-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
    margin-bottom: 40px;

    @include big {
      font-size: 2.083333333333333vw;
      margin-bottom: 2.083333333333333vw;
    }

    @include tablet-small {
      text-align: center;
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 32px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  .input-container {
    position: relative;
  }

  &__input-container {
    margin-bottom: 19px;
    @include hover {
      &:before {
        width: 100%;
      }
    }

    &.textarea {
      &:before {
        bottom: 10px;
      }
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 1px;
      background-color: $color-blue;
      transition: .5s;
      z-index: 1;
    }
  }

  &__input-error {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    top: 100%;
    left: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-red;
    margin-top: 4px;
    transition: .1s;

    &.active {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__input, &__textarea {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    padding: 12px 12px 8px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $color-white;
    width: 100%;
    color: $color-white;
    z-index: 2;

    &.error {
      border-color: $color-red;
    }

    &:active, &:focus {
      position: relative;
      padding: 12px 12px 7px;
      border-bottom: 2px solid $color-white;
      z-index: 2;
    }

    @include big {
      font-size: 1.0416666666666665vw;
      line-height: 1.4583333333333333vw;
      padding: 0.625vw 0.625vw 0.4166666666666667vw;
      border-bottom: 0.052083333333333336vw solid $color-white;
      margin-bottom: 0.9895833333333333vw;
    }

    &::placeholder {
      color: $color-light-gray-2;
    }
  }

  &__textarea {
    height: 156px;
    margin-bottom: 6px;

    @include big {
      height: 8.125vw;
      margin-bottom: 0.3125vw;
    }
    @include tablet-small {
      height: 153px;
      margin-bottom: 9px;
    }
  }

  &__button {
    max-width: 198px;

    @include big {
      max-width: 10.3125vw;
    }
    @include tablet-small {
      margin: 0 auto;
    }
    @include mobile {
      width: 100%;
      max-width: none;
    }
  }

  &__form-checkbox {
    margin-bottom: 35px;

    @include big {
      margin-bottom: 1.8229166666666667vw;
    }

    @include tablet-small {
      margin-bottom: 28px;
    }
  }
}
