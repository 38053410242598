.do {
  position: relative;
  overflow: hidden;
  padding-top: 98px;
  padding-bottom: 247px;

  @include big {
    padding-top: 5.104166666666667vw;
    padding-bottom: 12.864583333333332vw;
  }

  @include tablet-small {
    padding-bottom: 101px;
  }

  &__highlight-1 {
    position: absolute;
    right: 0;
    top: 280px;
    @include mobile {
      top: 150px;
      right: -600px;
    }

    svg {
      width: 755px;
      height: 655px;
      @include big {
        width: 39.323vw;
        height: 34.115vw;
      }
    }
  }

  &__highlight-2 {
    position: absolute;
    top: 502px;
    right: 0;
    @include mobile {
      top: 402px;
      right: -540px;
    }

    svg {
      width: 705px;
      height: 584px;
      @include big {
        width: 36.719vw;
        height: 30.417vw;
      }
    }
  }


  &__container {
    position: relative;
    display: flex;
    align-items: flex-start;
    z-index: 1;
    @media (max-width: $md) {
      flex-direction: column;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 94px;
    line-height: 98px;
    margin-right: 8.9%;
    letter-spacing: -0.03em;

    @media (max-width: $md) {
      margin-bottom: 60px;
    }

    @include big {
      font-size: 4.895833333333333vw;
      line-height: 5.104166666666667vw;
    }
    @include tablet-small {
      text-align: center;
      font-weight: 600;
      font-size: 34px;
      line-height: 44px;
      margin-bottom: 29px;
      width: 100%;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 116px;

    @include big {
      margin-bottom: 6.041666666666667vw;
    }

    @include tablet-small {
      flex-direction: column;
      margin-bottom: 63px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__button {
    display: block;
    width: 266px;
    margin: 67px auto 0;

    @include big {
      width: 13.854166666666668vw;
      margin: 3.4895833333333335vw auto 0;
    }

    @include tablet-small {
      margin-top: 29px;
    }
    @include mobile {
      width: 100%;
    }
  }

  &__item-left-bg {
    transform: scale(0);
    width: 100%;
    height: 100%;
    background: $color-blue;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100%;
    transition: 1s;
  }

  &__item-left {
    position: relative;
    flex-shrink: 0;
    overflow: hidden;
    width: 200px;
    height: 200px;
    margin-right: 81px;
    border-radius: 100%;

    svg {
      position: relative;
      z-index: 1;
    }


    @include big {
      width: 10.416666666666668vw;
      height: 10.416666666666668vw;
      margin-right: 4.21875vw;
    }

    @include tablet-small {
      width: 126px;
      height: 126px;
      margin-bottom: 39px;
      margin-right: 0;
    }

    &.one {
      svg {
        margin-left: 91px;
        margin-top: 52px;
        width: 55px;
        height: 154px;

        @include big {
          margin-left: 4.739583333333333vw;
          margin-top: 2.7083333333333335vw;
          width: 2.864583333333333vw;
          height: 8.020833333333334vw;
        }
        @include tablet-small {
          width: 35px;
          height: 97px;
          margin-left: 57px;
          margin-top: 33px;
        }
      }
    }

    &.two {
      svg {
        margin-left: 62px;
        margin-top: 37px;
        width: 113px;
        height: 168px;

        @include big {
          margin-left: 3.229166666666667vw;
          margin-top: 1.9270833333333335vw;
          width: 5.885416666666667vw;
          height: 8.75vw;
        }
        @include tablet-small {
          width: 71px;
          height: 106px;
          margin-left: 39px;
          margin-top: 23px;
        }
      }
    }

    &.three {
      svg {
        margin-left: 68px;
        margin-top: 55px;
        width: 102px;
        height: 155px;

        @include big {
          margin-left: 3.5416666666666665vw;
          margin-top: 2.864583333333333vw;
          width: 5.3125vw;
          height: 8.072916666666668vw;
        }
        @include tablet-small {
          width: 64px;
          height: 97px;
          margin-left: 43px;
          margin-top: 36px;
        }
      }
    }

    &.four {
      svg {
        margin-left: 60px;
        margin-top: 45px;
        width: 110px;
        height: 155px;

        @include big {
          margin-left: 3.125vw;
          margin-top: 2.34375vw;
          width: 5.729166666666666vw;
          height: 8.072916666666668vw;
        }
        @include tablet-small {
          width: 69px;
          height: 97px;
          margin-left: 38px;
          margin-top: 31px;
        }
      }
    }

    &.five {
      svg {
        margin-left: 60px;
        margin-top: 49px;
        width: 105px;
        height: 159px;

        @include big {
          margin-left: 3.125vw;
          margin-top: 2.5520833333333335vw;
          width: 5.46875vw;
          height: 8.28125vw;
        }
        @include tablet-small {
          width: 66px;
          height: 100px;
          margin-left: 38px;
          margin-top: 30px;
        }
      }
    }
  }

  &__item-right {
    transform: translateX(100vw);
    transition: 1.5s;
    @include tablet-small {
      text-align: center;
    }

    &.active {
      transform: translateX(0);
    }
  }

  &__item-title {
    font-weight: 600;
    font-size: 54px;
    line-height: 64px;
    letter-spacing: -0.03em;

    @include big {
      font-size: 2.8125vw;
      line-height: 3.3333333333333335vw;
    }

    @include tablet-small {
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;
      letter-spacing: normal;
    }
  }

  &__item-list {
    margin: -16px 0;
    padding-top: 28px;

    @include big {
      margin: -0.8333333333333334vw 0;
      padding-top: 1.4583333333333333vw;
    }

    @include tablet-small {
      padding-top: 31px;
    }
  }

  &__item-list-item {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    margin: 16px 0;

    @include big {
      font-size: 1.25vw;
      line-height: 1.6666666666666667vw;
      margin: 0.8333333333333334vw 0;
    }

    @include tablet-small {
      font-size: 18px;
      line-height: 24px;
    }
  }
}
