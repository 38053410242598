$ff_main: 'Manrope', sans-serif;

$mob: 579px;
$xs: 768px;
$sm: 1024px;
$md: 1200px;
$lg: 1600px;
$xl: 1920px;


$color-dark: #03030F;
$color-white: #FFFFFF;
$color-main: #03030F;
$color-dark-blue: #1D1D44;
$color-blue: #00D1FF;
$color-red: #CB5757;
$color-white-2: #FFFFFF;
$color-gray: #9E9E9E;
$color-light-gray: #AFB1C1;
$color-light-gray-2: #E0E0E0