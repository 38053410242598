.about {
  position: relative;
  overflow: hidden;
  padding-top: 150px;
  padding-bottom: 157px;

  @include big {
    padding-top: 7.8125vw;
    padding-bottom: 8.177083333333334vw;
  }

  @include tablet-small {
    padding-top: 44px;
    padding-bottom: 24px;
  }

  &__container {
    position: relative;
    display: flex;
    align-items: flex-start;
    max-width: 1088px;
    margin: 0 auto;
    z-index: 1;

    @include big {
      max-width: 56.666666666666664vw;
    }

    @include tablet-small {
      flex-direction: column;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 94px;
    line-height: 98px;
    margin-right: 81px;
    margin-top: 6px;
    white-space: nowrap;
    letter-spacing: -0.03em;

    @include big {
      font-size: 4.895833333333333vw;
      line-height: 5.104166666666667vw;
      margin-right: 4.21875vw;
      margin-top: 0.3125vw;
    }

    @include tablet-small {
      font-weight: 600;
      font-size: 34px;
      line-height: 44px;
      margin-bottom: 32px;
    }
  }

  &__desc {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 60px;

    @include big {
      font-size: 1.0416666666666665vw;
      line-height: 1.4583333333333333vw;
      margin-bottom: 3.125vw;
    }

    @include tablet-small {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }

    p {
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }

      @include big {
        margin-bottom: 1.6666666666666667vw;
      }
    }
  }

  &__highlight-1 {
    position: absolute;
    top: 296px;
    @include big {
      top: 37.901%;
    }
    @include mobile {
      left: -50px;
    }

    svg {
      width: 665px;
      height: 384px;
      @include big {
        width: 34.636vw;
        height: 20vw;
      }
    }
  }

  &__highlight-2 {
    position: absolute;
    top: 74px;
    @include big {
      top: 9.477%;
    }
    @include mobile {
      left: -50px;
    }

    svg {
      width: 607px;
      height: 455px;
      @include big {
        width: 31.615vw;
        height: 23.698vw;
      }
    }
  }

  &__button {
    display: block;
    max-width: 266px;
    margin-bottom: 40px;

    @include big {
      margin-bottom: 2.083333333333333vw;
      max-width: 13.854166666666668vw;
    }
    @include tablet-small {
      margin-bottom: 43px;
    }
    @include mobile {
      max-width: none;
      width: 100%;
    }
  }

  &__icon {
    display: inline-block;
    position: relative;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;

    &:before {
      content: "";
      position: absolute;
      bottom: 7px;
      left: 100%;
      margin-left: 10px;
      width: 92px;
      height: 2px;
      background: linear-gradient(90deg, $color-white 0%, rgba(255, 255, 255, 0) 101.25%);
    }

    @include big {
      font-size: 0.9375vw;
      line-height: 1.25vw;
    }
  }
}
