.notice-success {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: $color-white;
  padding: 24px;
  background-color: $color-dark-blue;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  max-width: 449px;
  transition: .3s;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }

  svg {
    margin-top: 20px;
    width: 48px;
    height: 48px;
  }
}
