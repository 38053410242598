.solutions {
  &__list {

  }

  &__item {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-around;
    padding: 0 9.16666vw;
    background-color: $color-main;
    z-index: 0;

    @include tablet {
      position: static;
    }

    @include tablet-small {
      height: auto;
    }

    &.smartshop {
      background-color: $color-main;

      @include tablet-small {
        padding: 99px 8px 77px;
      }

      .solutions {
        &__subtitle, &__desc {
          max-width: 933px;
          color: $color-white;

          @include big {
            max-width: 48.59375vw;
          }
        }

        &__title {
          @include tablet-small {
            margin-bottom: 100px;
          }

          svg {
            width: 825px;
            height: 260px;

            @include big {
              width: 42.96875vw;
              height: 13.541666666666666vw;
            }
            @include tablet-small {
              width: 287px;
              height: 260px;
            }
          }
        }
      }
    }

    &.lojs {
      background-color: $color-white;
      @include tablet-small {
        padding: 100px 8px 77px;
      }

      .solutions {
        &__subtitle, &__desc {
          max-width: 768px;
          color: $color-main;

          @include big {
            max-width: 40vw;
          }
        }

        &__title {
          @include tablet-small {
            margin-bottom: 94px;
          }

          svg {
            width: 560px;
            height: 300px;
            @include big {
              width: 29.166666666666668vw;
              height: 15.625vw;
            }
            @include tablet-small {
              width: 176px;
              height: 281px;
            }
          }
        }
      }
    }
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__subtitle {
    font-weight: 600;
    font-size: 54px;
    line-height: 64px;
    margin-bottom: 20px;
    max-width: 933px;
    letter-spacing: -0.03em;

    @include big {
      font-size: 2.8125vw;
      line-height: 3.3333333333333335vw;
      margin-bottom: 1.0416666666666665vw;
      max-width: 48.59375vw;
    }

    @include tablet-small {
      letter-spacing: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 24px;
    }
  }

  &__desc {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    max-width: 933px;

    @include big {
      font-size: 1.0416666666666665vw;
      line-height: 1.4583333333333333vw;
      max-width: 48.59375vw;
    }
    @include tablet-small {
      font-size: 18px;
      line-height: 24px;
    }
  }
}
