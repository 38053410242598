/*flex-box*/
/*flex-box*/
.footer__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px 29px 19px;
}
@media (min-width: 1921px) {
  .footer__container {
    padding: 1.5625vw 1.0416666667vw 1.5104166667vw 0.9895833333vw;
  }
}
@media (max-width: 768px) {
  .footer__container {
    flex-direction: column;
    padding: 26px 20px 34px 19px;
  }
}
.footer__logo {
  width: 187px;
  height: 36px;
}
@media (min-width: 1921px) {
  .footer__logo {
    width: 9.7395833333vw;
    height: 1.875vw;
  }
}
@media (max-width: 768px) {
  .footer__logo {
    margin-bottom: 38px;
  }
}
.footer__policy {
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
  color: #9E9E9E;
}
@media (min-width: 1921px) {
  .footer__policy {
    font-size: 0.9375vw;
    line-height: 0.8333333333vw;
  }
}

.solutions__item {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-around;
  padding: 0 9.16666vw;
  background-color: #03030F;
  z-index: 0;
}
@media (max-width: 1024px) {
  .solutions__item {
    position: static;
  }
}
@media (max-width: 768px) {
  .solutions__item {
    height: auto;
  }
}
.solutions__item.smartshop {
  background-color: #03030F;
}
@media (max-width: 768px) {
  .solutions__item.smartshop {
    padding: 99px 8px 77px;
  }
}
.solutions__item.smartshop .solutions__subtitle, .solutions__item.smartshop .solutions__desc {
  max-width: 933px;
  color: #FFFFFF;
}
@media (min-width: 1921px) {
  .solutions__item.smartshop .solutions__subtitle, .solutions__item.smartshop .solutions__desc {
    max-width: 48.59375vw;
  }
}
@media (max-width: 768px) {
  .solutions__item.smartshop .solutions__title {
    margin-bottom: 100px;
  }
}
.solutions__item.smartshop .solutions__title svg {
  width: 825px;
  height: 260px;
}
@media (min-width: 1921px) {
  .solutions__item.smartshop .solutions__title svg {
    width: 42.96875vw;
    height: 13.5416666667vw;
  }
}
@media (max-width: 768px) {
  .solutions__item.smartshop .solutions__title svg {
    width: 287px;
    height: 260px;
  }
}
.solutions__item.lojs {
  background-color: #FFFFFF;
}
@media (max-width: 768px) {
  .solutions__item.lojs {
    padding: 100px 8px 77px;
  }
}
.solutions__item.lojs .solutions__subtitle, .solutions__item.lojs .solutions__desc {
  max-width: 768px;
  color: #03030F;
}
@media (min-width: 1921px) {
  .solutions__item.lojs .solutions__subtitle, .solutions__item.lojs .solutions__desc {
    max-width: 40vw;
  }
}
@media (max-width: 768px) {
  .solutions__item.lojs .solutions__title {
    margin-bottom: 94px;
  }
}
.solutions__item.lojs .solutions__title svg {
  width: 560px;
  height: 300px;
}
@media (min-width: 1921px) {
  .solutions__item.lojs .solutions__title svg {
    width: 29.1666666667vw;
    height: 15.625vw;
  }
}
@media (max-width: 768px) {
  .solutions__item.lojs .solutions__title svg {
    width: 176px;
    height: 281px;
  }
}
.solutions__title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.solutions__subtitle {
  font-weight: 600;
  font-size: 54px;
  line-height: 64px;
  margin-bottom: 20px;
  max-width: 933px;
  letter-spacing: -0.03em;
}
@media (min-width: 1921px) {
  .solutions__subtitle {
    font-size: 2.8125vw;
    line-height: 3.3333333333vw;
    margin-bottom: 1.0416666667vw;
    max-width: 48.59375vw;
  }
}
@media (max-width: 768px) {
  .solutions__subtitle {
    letter-spacing: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 24px;
  }
}
.solutions__desc {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  max-width: 933px;
}
@media (min-width: 1921px) {
  .solutions__desc {
    font-size: 1.0416666667vw;
    line-height: 1.4583333333vw;
    max-width: 48.59375vw;
  }
}
@media (max-width: 768px) {
  .solutions__desc {
    font-size: 18px;
    line-height: 24px;
  }
}

.portfolio {
  padding-top: 78px;
  padding-bottom: 172px;
}
@media (min-width: 1921px) {
  .portfolio {
    padding-top: 4.0625vw;
    padding-bottom: 8.9583333333vw;
  }
}
@media (max-width: 1024px) {
  .portfolio {
    padding-bottom: 100px;
  }
}
@media (max-width: 768px) {
  .portfolio {
    padding-bottom: 5px;
  }
}
.portfolio__title {
  text-align: center;
  font-weight: 600;
  font-size: 94px;
  line-height: 98px;
  margin-bottom: 55px;
  letter-spacing: -0.03em;
}
@media (min-width: 1921px) {
  .portfolio__title {
    font-size: 4.8958333333vw;
    line-height: 5.1041666667vw;
    margin-bottom: 2.8645833333vw;
  }
}
@media (max-width: 768px) {
  .portfolio__title {
    font-weight: 600;
    font-size: 34px;
    line-height: 44px;
    margin-bottom: 28px;
  }
}
.portfolio__list {
  display: flex;
  flex-wrap: nowrap;
  width: fit-content;
}
@media (max-width: 768px) {
  .portfolio__list {
    margin: 0 -8px 0 8px;
  }
}
.portfolio .swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
}
.portfolio .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: #FFFFFF;
  opacity: 0.5;
  border-radius: 6px;
  margin: 0 4px;
  transition: 0.3s;
}
.portfolio .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 36px;
  opacity: 1;
}
.portfolio__item {
  width: calc(33.33333% - 32px) !important;
  margin: 0 16px 59px;
}
@media (max-width: 1024px) {
  .portfolio__item {
    width: 304px !important;
  }
}
@media (max-width: 768px) {
  .portfolio__item {
    margin: 0 32px 0 0;
  }
}
.portfolio__item-image {
  position: relative;
  display: block;
  margin-bottom: 32px;
  padding-bottom: 98.688%;
}
@media (min-width: 1921px) {
  .portfolio__item-image {
    height: 31.25vw;
    margin-bottom: 1.6666666667vw;
  }
}
@media (max-width: 768px) {
  .portfolio__item-image {
    margin-bottom: 30px;
  }
}
.portfolio__item-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  max-height: 600px;
  object-fit: cover;
  object-position: center;
}
@media (min-width: 1921px) {
  .portfolio__item-image img {
    border-radius: 0.2604166667vw;
    max-height: 31.25vw;
  }
}
@media (max-width: 1024px) {
  .portfolio__item-image img {
    max-height: 300px;
  }
}
.portfolio__item-title {
  font-weight: 600;
  font-size: 34px;
  line-height: 44px;
  margin-bottom: 15px;
  letter-spacing: -0.03em;
}
@media (min-width: 1921px) {
  .portfolio__item-title {
    font-size: 1.7708333333vw;
    line-height: 2.2916666667vw;
    margin-bottom: 0.78125vw;
  }
}
@media (max-width: 768px) {
  .portfolio__item-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
    letter-spacing: normal;
  }
}
.portfolio__item-desc {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}
@media (min-width: 1921px) {
  .portfolio__item-desc {
    font-size: 1.0416666667vw;
    line-height: 1.4583333333vw;
  }
}
@media (max-width: 768px) {
  .portfolio__item-desc {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
}

.do {
  position: relative;
  overflow: hidden;
  padding-top: 98px;
  padding-bottom: 247px;
}
@media (min-width: 1921px) {
  .do {
    padding-top: 5.1041666667vw;
    padding-bottom: 12.8645833333vw;
  }
}
@media (max-width: 768px) {
  .do {
    padding-bottom: 101px;
  }
}
.do__highlight-1 {
  position: absolute;
  right: 0;
  top: 280px;
}
@media (max-width: 579px) {
  .do__highlight-1 {
    top: 150px;
    right: -600px;
  }
}
.do__highlight-1 svg {
  width: 755px;
  height: 655px;
}
@media (min-width: 1921px) {
  .do__highlight-1 svg {
    width: 39.323vw;
    height: 34.115vw;
  }
}
.do__highlight-2 {
  position: absolute;
  top: 502px;
  right: 0;
}
@media (max-width: 579px) {
  .do__highlight-2 {
    top: 402px;
    right: -540px;
  }
}
.do__highlight-2 svg {
  width: 705px;
  height: 584px;
}
@media (min-width: 1921px) {
  .do__highlight-2 svg {
    width: 36.719vw;
    height: 30.417vw;
  }
}
.do__container {
  position: relative;
  display: flex;
  align-items: flex-start;
  z-index: 1;
}
@media (max-width: 1200px) {
  .do__container {
    flex-direction: column;
  }
}
.do__title {
  font-weight: 600;
  font-size: 94px;
  line-height: 98px;
  margin-right: 8.9%;
  letter-spacing: -0.03em;
}
@media (max-width: 1200px) {
  .do__title {
    margin-bottom: 60px;
  }
}
@media (min-width: 1921px) {
  .do__title {
    font-size: 4.8958333333vw;
    line-height: 5.1041666667vw;
  }
}
@media (max-width: 768px) {
  .do__title {
    text-align: center;
    font-weight: 600;
    font-size: 34px;
    line-height: 44px;
    margin-bottom: 29px;
    width: 100%;
  }
}
.do__item {
  display: flex;
  align-items: center;
  margin-bottom: 116px;
}
@media (min-width: 1921px) {
  .do__item {
    margin-bottom: 6.0416666667vw;
  }
}
@media (max-width: 768px) {
  .do__item {
    flex-direction: column;
    margin-bottom: 63px;
  }
}
.do__item:last-child {
  margin-bottom: 0;
}
.do__button {
  display: block;
  width: 266px;
  margin: 67px auto 0;
}
@media (min-width: 1921px) {
  .do__button {
    width: 13.8541666667vw;
    margin: 3.4895833333vw auto 0;
  }
}
@media (max-width: 768px) {
  .do__button {
    margin-top: 29px;
  }
}
@media (max-width: 579px) {
  .do__button {
    width: 100%;
  }
}
.do__item-left-bg {
  transform: scale(0);
  width: 100%;
  height: 100%;
  background: #00D1FF;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 100%;
  transition: 1s;
}
.do__item-left {
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  width: 200px;
  height: 200px;
  margin-right: 81px;
  border-radius: 100%;
}
.do__item-left svg {
  position: relative;
  z-index: 1;
}
@media (min-width: 1921px) {
  .do__item-left {
    width: 10.4166666667vw;
    height: 10.4166666667vw;
    margin-right: 4.21875vw;
  }
}
@media (max-width: 768px) {
  .do__item-left {
    width: 126px;
    height: 126px;
    margin-bottom: 39px;
    margin-right: 0;
  }
}
.do__item-left.one svg {
  margin-left: 91px;
  margin-top: 52px;
  width: 55px;
  height: 154px;
}
@media (min-width: 1921px) {
  .do__item-left.one svg {
    margin-left: 4.7395833333vw;
    margin-top: 2.7083333333vw;
    width: 2.8645833333vw;
    height: 8.0208333333vw;
  }
}
@media (max-width: 768px) {
  .do__item-left.one svg {
    width: 35px;
    height: 97px;
    margin-left: 57px;
    margin-top: 33px;
  }
}
.do__item-left.two svg {
  margin-left: 62px;
  margin-top: 37px;
  width: 113px;
  height: 168px;
}
@media (min-width: 1921px) {
  .do__item-left.two svg {
    margin-left: 3.2291666667vw;
    margin-top: 1.9270833333vw;
    width: 5.8854166667vw;
    height: 8.75vw;
  }
}
@media (max-width: 768px) {
  .do__item-left.two svg {
    width: 71px;
    height: 106px;
    margin-left: 39px;
    margin-top: 23px;
  }
}
.do__item-left.three svg {
  margin-left: 68px;
  margin-top: 55px;
  width: 102px;
  height: 155px;
}
@media (min-width: 1921px) {
  .do__item-left.three svg {
    margin-left: 3.5416666667vw;
    margin-top: 2.8645833333vw;
    width: 5.3125vw;
    height: 8.0729166667vw;
  }
}
@media (max-width: 768px) {
  .do__item-left.three svg {
    width: 64px;
    height: 97px;
    margin-left: 43px;
    margin-top: 36px;
  }
}
.do__item-left.four svg {
  margin-left: 60px;
  margin-top: 45px;
  width: 110px;
  height: 155px;
}
@media (min-width: 1921px) {
  .do__item-left.four svg {
    margin-left: 3.125vw;
    margin-top: 2.34375vw;
    width: 5.7291666667vw;
    height: 8.0729166667vw;
  }
}
@media (max-width: 768px) {
  .do__item-left.four svg {
    width: 69px;
    height: 97px;
    margin-left: 38px;
    margin-top: 31px;
  }
}
.do__item-left.five svg {
  margin-left: 60px;
  margin-top: 49px;
  width: 105px;
  height: 159px;
}
@media (min-width: 1921px) {
  .do__item-left.five svg {
    margin-left: 3.125vw;
    margin-top: 2.5520833333vw;
    width: 5.46875vw;
    height: 8.28125vw;
  }
}
@media (max-width: 768px) {
  .do__item-left.five svg {
    width: 66px;
    height: 100px;
    margin-left: 38px;
    margin-top: 30px;
  }
}
.do__item-right {
  transform: translateX(100vw);
  transition: 1.5s;
}
@media (max-width: 768px) {
  .do__item-right {
    text-align: center;
  }
}
.do__item-right.active {
  transform: translateX(0);
}
.do__item-title {
  font-weight: 600;
  font-size: 54px;
  line-height: 64px;
  letter-spacing: -0.03em;
}
@media (min-width: 1921px) {
  .do__item-title {
    font-size: 2.8125vw;
    line-height: 3.3333333333vw;
  }
}
@media (max-width: 768px) {
  .do__item-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: normal;
  }
}
.do__item-list {
  margin: -16px 0;
  padding-top: 28px;
}
@media (min-width: 1921px) {
  .do__item-list {
    margin: -0.8333333333vw 0;
    padding-top: 1.4583333333vw;
  }
}
@media (max-width: 768px) {
  .do__item-list {
    padding-top: 31px;
  }
}
.do__item-list-item {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  margin: 16px 0;
}
@media (min-width: 1921px) {
  .do__item-list-item {
    font-size: 1.25vw;
    line-height: 1.6666666667vw;
    margin: 0.8333333333vw 0;
  }
}
@media (max-width: 768px) {
  .do__item-list-item {
    font-size: 18px;
    line-height: 24px;
  }
}

.technology {
  padding-top: 99px;
  padding-bottom: 92px;
  background-color: #FFFFFF;
}
@media (min-width: 1921px) {
  .technology {
    padding-top: 5.15625vw;
    padding-bottom: 4.7916666667vw;
  }
}
@media (max-width: 768px) {
  .technology {
    padding-top: 51px;
    padding-bottom: 48px;
  }
}
.technology__title {
  font-weight: 600;
  font-size: 94px;
  line-height: 98px;
  margin-bottom: 55px;
  text-align: center;
  color: #03030F;
  letter-spacing: -0.03em;
}
@media (min-width: 1921px) {
  .technology__title {
    font-size: 4.8958333333vw;
    line-height: 5.1041666667vw;
    margin-bottom: 2.8645833333vw;
  }
}
@media (max-width: 768px) {
  .technology__title {
    font-weight: 600;
    font-size: 34px;
    line-height: 44px;
    margin-bottom: 48px;
  }
}
.technology__list {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 1921px) {
  .technology__list {
    margin: 0 -2.9166666667vw;
  }
}
@media (max-width: 768px) {
  .technology__list {
    margin: 0 -30px;
    width: 1050px;
  }
}
@media (max-width: 768px) {
  .technology__list-container {
    overflow-x: auto;
    overflow-y: hidden;
    margin-right: -8px;
  }
}
.technology__item {
  margin: 0 3.234% 6.846%;
  width: 7.6192%;
  height: 128px;
}
@media (max-width: 1600px) {
  .technology__item {
    height: 8vw;
  }
}
@media (min-width: 1921px) {
  .technology__item {
    margin: 0 2.9166666667vw 5.9895833333vw;
    width: 6.6666666667vw;
    height: 6.6666666667vw;
  }
}
@media (max-width: 768px) {
  .technology__item {
    width: 90px;
    height: 90px;
    flex-shrink: 0;
    margin: 0 30px 79px;
  }
}
.technology__item:nth-last-child(-n+7) {
  margin-bottom: 0;
}

.about {
  position: relative;
  overflow: hidden;
  padding-top: 150px;
  padding-bottom: 157px;
}
@media (min-width: 1921px) {
  .about {
    padding-top: 7.8125vw;
    padding-bottom: 8.1770833333vw;
  }
}
@media (max-width: 768px) {
  .about {
    padding-top: 44px;
    padding-bottom: 24px;
  }
}
.about__container {
  position: relative;
  display: flex;
  align-items: flex-start;
  max-width: 1088px;
  margin: 0 auto;
  z-index: 1;
}
@media (min-width: 1921px) {
  .about__container {
    max-width: 56.6666666667vw;
  }
}
@media (max-width: 768px) {
  .about__container {
    flex-direction: column;
  }
}
.about__title {
  font-weight: 600;
  font-size: 94px;
  line-height: 98px;
  margin-right: 81px;
  margin-top: 6px;
  white-space: nowrap;
  letter-spacing: -0.03em;
}
@media (min-width: 1921px) {
  .about__title {
    font-size: 4.8958333333vw;
    line-height: 5.1041666667vw;
    margin-right: 4.21875vw;
    margin-top: 0.3125vw;
  }
}
@media (max-width: 768px) {
  .about__title {
    font-weight: 600;
    font-size: 34px;
    line-height: 44px;
    margin-bottom: 32px;
  }
}
.about__desc {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 60px;
}
@media (min-width: 1921px) {
  .about__desc {
    font-size: 1.0416666667vw;
    line-height: 1.4583333333vw;
    margin-bottom: 3.125vw;
  }
}
@media (max-width: 768px) {
  .about__desc {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}
.about__desc p {
  margin-bottom: 32px;
}
.about__desc p:last-child {
  margin-bottom: 0;
}
@media (min-width: 1921px) {
  .about__desc p {
    margin-bottom: 1.6666666667vw;
  }
}
.about__highlight-1 {
  position: absolute;
  top: 296px;
}
@media (min-width: 1921px) {
  .about__highlight-1 {
    top: 37.901%;
  }
}
@media (max-width: 579px) {
  .about__highlight-1 {
    left: -50px;
  }
}
.about__highlight-1 svg {
  width: 665px;
  height: 384px;
}
@media (min-width: 1921px) {
  .about__highlight-1 svg {
    width: 34.636vw;
    height: 20vw;
  }
}
.about__highlight-2 {
  position: absolute;
  top: 74px;
}
@media (min-width: 1921px) {
  .about__highlight-2 {
    top: 9.477%;
  }
}
@media (max-width: 579px) {
  .about__highlight-2 {
    left: -50px;
  }
}
.about__highlight-2 svg {
  width: 607px;
  height: 455px;
}
@media (min-width: 1921px) {
  .about__highlight-2 svg {
    width: 31.615vw;
    height: 23.698vw;
  }
}
.about__button {
  display: block;
  max-width: 266px;
  margin-bottom: 40px;
}
@media (min-width: 1921px) {
  .about__button {
    margin-bottom: 2.0833333333vw;
    max-width: 13.8541666667vw;
  }
}
@media (max-width: 768px) {
  .about__button {
    margin-bottom: 43px;
  }
}
@media (max-width: 579px) {
  .about__button {
    max-width: none;
    width: 100%;
  }
}
.about__icon {
  display: inline-block;
  position: relative;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}
.about__icon:before {
  content: "";
  position: absolute;
  bottom: 7px;
  left: 100%;
  margin-left: 10px;
  width: 92px;
  height: 2px;
  background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 101.25%);
}
@media (min-width: 1921px) {
  .about__icon {
    font-size: 0.9375vw;
    line-height: 1.25vw;
  }
}

.contacts {
  position: relative;
  overflow: hidden;
  padding-top: 203px;
  padding-bottom: 103px;
}
@media (max-width: 1024px) {
  .contacts {
    padding-top: 98px;
  }
}
@media (min-width: 1921px) {
  .contacts {
    padding-top: 10.5729166667vw;
    padding-bottom: 5.3645833333vw;
  }
}
.contacts__highlight-1 {
  position: absolute;
  top: 22%;
  left: 43.5%;
  opacity: 0.6;
}
@media (min-width: 1921px) {
  .contacts__highlight-1 {
    top: 10.4vw;
    left: 43.5vw;
  }
}
@media (max-width: 579px) {
  .contacts__highlight-1 {
    top: 39%;
    left: 17.5%;
    opacity: 0.7;
  }
}
.contacts__highlight-1 svg {
  width: 493px;
  height: 639px;
}
@media (min-width: 1921px) {
  .contacts__highlight-1 svg {
    width: 25.6776vw;
    height: 33.282vw;
  }
}
.contacts__highlight-2 {
  position: absolute;
  top: 39.6%;
  left: 34.4%;
  opacity: 0.6;
}
@media (min-width: 1921px) {
  .contacts__highlight-2 {
    top: 18.725vw;
    left: 34.4vw;
  }
}
@media (max-width: 579px) {
  .contacts__highlight-2 {
    top: 54.6%;
    left: -16.6%;
    opacity: 0.7;
  }
}
.contacts__highlight-2 svg {
  width: 609px;
  height: 494px;
}
@media (min-width: 1921px) {
  .contacts__highlight-2 svg {
    width: 31.719vw;
    height: 25.7299vw;
  }
}
.contacts__title {
  font-weight: 600;
  font-size: 94px;
  line-height: 98px;
  letter-spacing: -0.03em;
  margin-bottom: 54px;
  max-width: 722px;
}
@media (min-width: 1921px) {
  .contacts__title {
    font-size: 4.8958333333vw;
    line-height: 5.1041666667vw;
    margin-bottom: 2.8125vw;
    max-width: 37.6041666667vw;
  }
}
@media (max-width: 1024px) {
  .contacts__title {
    font-weight: 600;
    font-size: 34px;
    line-height: 44px;
    margin-bottom: 33px;
  }
}
.contacts__left {
  margin-top: 5px;
  font-style: normal;
  z-index: 1;
}
@media (min-width: 1921px) {
  .contacts__left {
    margin-top: 0.2604166667vw;
  }
}
@media (max-width: 1024px) {
  .contacts__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
  }
}
@media (max-width: 768px) {
  .contacts__left {
    margin-bottom: 23px;
  }
}
.contacts__mail, .contacts__address {
  display: flex;
  align-items: flex-start;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 32px;
}
@media (min-width: 1921px) {
  .contacts__mail, .contacts__address {
    font-size: 1.25vw;
    line-height: 1.6666666667vw;
    margin-bottom: 1.6666666667vw;
  }
}
@media (max-width: 768px) {
  .contacts__mail, .contacts__address {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 30px;
  }
}
.contacts__mail svg, .contacts__address svg {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}
@media (min-width: 1921px) {
  .contacts__mail svg, .contacts__address svg {
    width: 1.6666666667vw;
    height: 1.6666666667vw;
    margin-right: 0.4166666667vw;
  }
}
.contacts__address-text p {
  display: block;
  margin-bottom: 12px;
}
@media (min-width: 1921px) {
  .contacts__address-text p {
    margin-bottom: 0.625vw;
  }
}
@media (max-width: 768px) {
  .contacts__address-text p {
    margin-bottom: 8px;
  }
}
.contacts__container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1408px;
  margin: 0 auto;
}
@media (min-width: 1921px) {
  .contacts__container {
    max-width: 73.3333333333vw;
  }
}
@media (max-width: 1024px) {
  .contacts__container {
    flex-direction: column;
    align-items: center;
  }
}
.contacts__right {
  max-width: 448px;
  width: 100%;
  z-index: 1;
}
@media (min-width: 1921px) {
  .contacts__right {
    max-width: 23.3333333333vw;
  }
}
.contacts__form-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 100%;
  margin-bottom: 40px;
}
@media (min-width: 1921px) {
  .contacts__form-title {
    font-size: 2.0833333333vw;
    margin-bottom: 2.0833333333vw;
  }
}
@media (max-width: 768px) {
  .contacts__form-title {
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 32px;
  }
}
.contacts__form {
  display: flex;
  flex-direction: column;
}
.contacts .input-container {
  position: relative;
}
.contacts__input-container {
  margin-bottom: 19px;
}
@media (hover: hover) {
  .contacts__input-container:hover:before {
    width: 100%;
  }
}
.contacts__input-container.textarea:before {
  bottom: 10px;
}
.contacts__input-container:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: #00D1FF;
  transition: 0.5s;
  z-index: 1;
}
.contacts__input-error {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  top: 100%;
  left: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #CB5757;
  margin-top: 4px;
  transition: 0.1s;
}
.contacts__input-error.active {
  opacity: 1;
  pointer-events: auto;
}
.contacts__input, .contacts__textarea {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  padding: 12px 12px 8px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #FFFFFF;
  width: 100%;
  color: #FFFFFF;
  z-index: 2;
}
.contacts__input.error, .contacts__textarea.error {
  border-color: #CB5757;
}
.contacts__input:active, .contacts__input:focus, .contacts__textarea:active, .contacts__textarea:focus {
  position: relative;
  padding: 12px 12px 7px;
  border-bottom: 2px solid #FFFFFF;
  z-index: 2;
}
@media (min-width: 1921px) {
  .contacts__input, .contacts__textarea {
    font-size: 1.0416666667vw;
    line-height: 1.4583333333vw;
    padding: 0.625vw 0.625vw 0.4166666667vw;
    border-bottom: 0.0520833333vw solid #FFFFFF;
    margin-bottom: 0.9895833333vw;
  }
}
.contacts__input::placeholder, .contacts__textarea::placeholder {
  color: #E0E0E0;
}
.contacts__textarea {
  height: 156px;
  margin-bottom: 6px;
}
@media (min-width: 1921px) {
  .contacts__textarea {
    height: 8.125vw;
    margin-bottom: 0.3125vw;
  }
}
@media (max-width: 768px) {
  .contacts__textarea {
    height: 153px;
    margin-bottom: 9px;
  }
}
.contacts__button {
  max-width: 198px;
}
@media (min-width: 1921px) {
  .contacts__button {
    max-width: 10.3125vw;
  }
}
@media (max-width: 768px) {
  .contacts__button {
    margin: 0 auto;
  }
}
@media (max-width: 579px) {
  .contacts__button {
    width: 100%;
    max-width: none;
  }
}
.contacts__form-checkbox {
  margin-bottom: 35px;
}
@media (min-width: 1921px) {
  .contacts__form-checkbox {
    margin-bottom: 1.8229166667vw;
  }
}
@media (max-width: 768px) {
  .contacts__form-checkbox {
    margin-bottom: 28px;
  }
}

.notice-success {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #FFFFFF;
  padding: 24px;
  background-color: #1D1D44;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  max-width: 449px;
  transition: 0.3s;
}
.notice-success.active {
  opacity: 1;
  pointer-events: auto;
}
.notice-success svg {
  margin-top: 20px;
  width: 48px;
  height: 48px;
}