.technology {
  padding-top: 99px;
  padding-bottom: 92px;
  background-color: $color-white;

  @include big {
    padding-top: 5.15625vw;
    padding-bottom: 4.791666666666667vw;
  }

  @include tablet-small {
    padding-top: 51px;
    padding-bottom: 48px;
  }

  &__title {
    font-weight: 600;
    font-size: 94px;
    line-height: 98px;
    margin-bottom: 55px;
    text-align: center;
    color: $color-main;
    letter-spacing: -0.03em;

    @include big {
      font-size: 4.895833333333333vw;
      line-height: 5.104166666666667vw;
      margin-bottom: 2.864583333333333vw;
    }

    @include tablet-small {
      font-weight: 600;
      font-size: 34px;
      line-height: 44px;
      margin-bottom: 48px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;

    @include big {
      margin: 0 -2.9166666666666665vw;
    }
    @include tablet-small {
      margin: 0 -30px;
      width: 1050px;
    }
  }

  &__list-container {
    @include tablet-small {
      overflow-x: auto;
      overflow-y: hidden;
      margin-right: -8px;
    }
  }

  &__item {
    margin: 0 3.234% 6.846%;
    width: 7.6192%;
    height: 128px;
    @media (max-width: 1600px) {
      height: 8vw;
    }
    @include big {
      margin: 0 2.9166666666666665vw 5.989583333333334vw;
      width: 6.666666666666667vw;
      height: 6.666666666666667vw;
    }

    @include tablet-small {
      width: 90px;
      height: 90px;
      flex-shrink: 0;
      margin: 0 30px 79px;
    }

    &:nth-last-child(-n+7) {
      margin-bottom: 0;
    }
  }
}
