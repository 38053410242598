@mixin tablet {
    @media (max-width: #{$sm}) {
        @content;
    }
}

@mixin tablet-small {
    @media (max-width: #{$xs}) {
        @content;
    }
}

@mixin big {
  @media  (min-width: #{$xl + 1}) {
    @content
  }
}

@mixin mobile {
    @media (max-width: #{$mob}) {
        @content;
    }
}

@mixin hover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}

@mixin disabled {
  &.disabled, &[disabled] {
    @content
  }
}

@mixin active {
  &.active, &:active {
    @content
  }
}

@mixin b_64($val) {
  background-image: url($val);
}

@mixin base_64($val) {
  background-image: url('data:image/png;base64,' + $val);
}

@mixin base_64_important($val) {
  background-image: url('data:image/png;base64,' + $val) !important;
}

@mixin pl_color($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
}

@mixin bg_img($repeat, $position, $size) {
  background-repeat: $repeat;
  background-position: $position;
  background-size: $size;
}

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin absolute($args...) {
  @include position(absolute, $args...);
}

@mixin relative($args...) {
  @include position(relative, $args...);
}

/// Shorthand mixin for fixed positioning
/// Serves as an alias for `position(fixed, ...)`
/// @param {Arglist} $args - Offsets
/// @require {mixin} position
@mixin fixed($args...) {
  @include position(fixed, $args...);
}

@mixin static($args...) {
  @include position(static, $args...);
}

/*flex-box*/
@mixin display($flex, $align, $justify) {
  display: $flex;
  align-items: $align;
  justify-content: $justify;
}

@mixin flex($args...) {
  @include display(flex, $args...);
}

@mixin display($inline-flex, $align, $justify) {
  display: $inline-flex;
  align-items: $align;
  justify-content: $justify;
}

@mixin inline-flex($args...) {
  @include display(inline-flex, $args...);
}
/*flex-box*/

