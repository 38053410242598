.portfolio {
  padding-top: 78px;
  padding-bottom: 172px;

  @include big {
    padding-top: 4.0625vw;
    padding-bottom: 8.958333333333334vw;
  }

  @include tablet {
    padding-bottom: 100px;
  }

  @include tablet-small {
    padding-bottom: 5px;
  }

  &__title {
    text-align: center;
    font-weight: 600;
    font-size: 94px;
    line-height: 98px;
    margin-bottom: 55px;
    letter-spacing: -0.03em;

    @include big {
      font-size: 4.895833333333333vw;
      line-height: 5.104166666666667vw;
      margin-bottom: 2.864583333333333vw;
    }

    @include tablet-small {
      font-weight: 600;
      font-size: 34px;
      line-height: 44px;
      margin-bottom: 28px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: nowrap;
    width: fit-content;
    @include tablet-small {
      margin: 0 -8px 0 8px;
    }
  }

  .swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6px;
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background: $color-white;
    opacity: 0.5;
    border-radius: 6px;
    margin: 0 4px;
    transition: .3s;

    &.swiper-pagination-bullet-active {
      width: 36px;
      opacity: 1;
    }
  }

  &__item {
    width: calc(33.33333% - 32px) !important;
    margin: 0 16px 59px;
    @include big {
      //width: calc(33.33333% - 1.6666666666666667vw) !important;
      //margin: 0 0.8333333333333334vw 3.0729166666666665vw;
    }
    @include tablet {
      width: 304px !important;
    }
    @include tablet-small {
      margin: 0 32px 0 0;
    }
  }

  &__item-image {
    position: relative;
    display: block;
    margin-bottom: 32px;
    padding-bottom: 98.688%;
    @include big {
      height: 31.25vw;
      margin-bottom: 1.6666666666666667vw;
    }

    @include tablet-small {
      margin-bottom: 30px;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      max-height: 600px;
      object-fit: cover;
      object-position: center;

      @include big {
        border-radius: 0.26041666666666663vw;
        max-height: 31.25vw;
      }
      @include tablet {
        max-height: 300px;
      }
    }
  }

  &__item-title {
    font-weight: 600;
    font-size: 34px;
    line-height: 44px;
    margin-bottom: 15px;
    letter-spacing: -0.03em;
    @include big {
      font-size: 1.7708333333333333vw;
      line-height: 2.2916666666666665vw;
      margin-bottom: 0.78125vw;
    }
    @include tablet-small {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 16px;
      letter-spacing: normal;
    }
  }

  &__item-desc {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    @include big {
      font-size: 1.0416666666666665vw;
      line-height: 1.4583333333333333vw;
    }
    @include tablet-small {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
    }
  }
}
